import React, { FC, useEffect, useState } from 'react'
import cn from 'classname'
// @ts-ignore
import CommentCard from './renderComment'
import ReplyForm, { api } from './replyForm'
import { IUser } from '../AccountZone'
import isMobile from '../../helpers/isMobile'
import { Loader } from '../../components/Loader'

export type ThumbsStat = {
	count: number
	down: number
	up: number
	myVote: '' | 'up' | 'down'
}

export type Comment = {
	author_name: string
	children_comments: Comment[]
	content: string
	created_at: string
	id: number
	parent_id: number | null
	creator?: IUser

	thumbs_stat: ThumbsStat
}

export const ReplyContent = React.createContext<{
	reply: number | null
	replyUrl: string
	changeReply: (value: number | null) => void
	updatePage: () => void
}>(null)

export type PaginationRequest<T> = {
	paginate: PaginatedField<T>
	total: number
}

export type PaginatedField<T> = {
	current_page: number
	data: Array<T>
	first_page_url: string
	from: number | null
	next_page_url: string | null
	path: string
	per_page: number
	prev_page_url: string | null
	to: number | null
}

export const getHeightCommentsZone = () => {
	if (isMobile()) {
		return 2419
	}

	return 2349
}

const RenderComments: FC<{
	url: string
	replyUrl: string
	loadHeight: number
	preCommentText?: string
	title?: string
	onlyComments?: boolean
	onLoad?: () => {}
}> = ({
	url,
	replyUrl,
	loadHeight,
	preCommentText = ``,
	title = undefined,
	onlyComments = false,
	onLoad,
}) => {
	const [reply, setReply] = useState<null | number>(null)
	const [page, setPage] = useState<number>(1)
	const [comments, setComments] = useState<Comment[]>([])
	const [nextPage, setNextPage] = useState<number | null>(2)
	const [allowNext, setAllowNext] = useState<boolean>(false)
	const [showBottomForm, setShowBottomForm] = useState<boolean>(false)
	const [firstLoading, setFirstLoading] = useState<boolean>(true)
	const [loading, setLoading] = useState<boolean>(false)

	const changeReply = value => setReply(value)

	const updatePage = async () => {
		let promises = []
		for (let i = 1; i <= nextPage; i++) {
			promises.push(
				new Promise(resolve => {
					api<PaginationRequest<Comment>>(`${url}?page=${i}`, {
						method: `POST`,
						headers: {},
						body: JSON.stringify({
							// search,
							// section
						}),
					}).then(response => {
						resolve(response.paginate.data)
					})
				})
			)
		}
		let result = await Promise.all(promises)

		let commentsData = []

		result.map(data => {
			commentsData = [...commentsData, ...data]
		})

		setComments(commentsData)
	}

	const fetchComments = async (refresh = false) => {
		if (refresh) {
			setAllowNext(false)
			setPage(1)
		}

		setLoading(true)

		let response = await api<PaginationRequest<Comment>>(`${url}?page=${refresh ? 1 : nextPage}`, {
			method: `POST`,
			headers: {},
			body: JSON.stringify({
				// search,
				// section
			}),
		})

		if (response.paginate.from == response.paginate.to || response.paginate.next_page_url == null) {
			setAllowNext(false)
		} else {
			setAllowNext(true)

			if (page + 1 > response.paginate.to) {
				setAllowNext(false)
				setNextPage(1)
			} else if (refresh) {
				setNextPage(page + 1)
			} else {
				setNextPage(nextPage + 1)
			}
		}

		setPage(response.paginate.current_page)

		if (nextPage === 1 || refresh) {
			setComments(response.paginate.data)
		} else {
			setComments([...comments, ...response.paginate.data])
		}

		setFirstLoading(false)

		setLoading(false)
	}

	useEffect(() => {
		;(async () => {
			setPage(1)
			setNextPage(2)
			await fetchComments(true)
		})()
	}, [])

	useEffect(() => {
		if (!firstLoading) {
			setTimeout(() => {
				onLoad()
			}, 500)
		}
	}, [firstLoading])

	const renderComments = () => {
		if (comments.length > 0) {
			return (
				<>
					{comments.map(comment => (
						<CommentCard comment={comment} key={comment.id} hideReply={onlyComments} />
					))}{' '}
					{allowNext ? (
						<div className="load-more-wrapper">
							<div
								className={cn(`btn btn--primary btn--outline load-more`, {
									disabled: loading,
									loading,
								})}
								onClick={() => {
									fetchComments()
								}}
							>
								Загрузить ещё
							</div>
						</div>
					) : undefined}
				</>
			)
		}

		return <p className="typography">Комментариев пока нет. :(</p>
	}

	if (firstLoading) {
		return <Loader height={loadHeight} />
	}

	if (onlyComments) {
		return (
			<ReplyContent.Provider value={{ reply, changeReply, replyUrl, updatePage }}>
				{renderComments()}
			</ReplyContent.Provider>
		)
	}

	return (
		<ReplyContent.Provider value={{ reply, changeReply, replyUrl, updatePage }}>
			<ReplyForm to={null} />

			<h2 className="main-title main-title--second">Комментарии</h2>

			{renderComments()}

			{/* <h2 className="main-title main-title--second" id="commentform"> */}
			{/*	Написать комментарий */}
			{/* </h2> */}

			{
				showBottomForm ? (
					<ReplyForm
						to={null}
						onReply={() => {
							setShowBottomForm(false)
						}}
					/>
				) : undefined
				// <div className="btn btn--secod" onClick={() => setShowBottomForm(true)}>
				// 	Оставить комментарий
				// </div>
			}
		</ReplyContent.Provider>
	)
}

export default RenderComments
