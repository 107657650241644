import React, { FC, useContext, useState } from 'react'
import cn from 'classname'
import { Comment, ReplyContent, ThumbsStat } from './index'
import m from '../../helpers/moment'
import { makeSnack } from '../../common/snaks'
import ReplyForm, { api, makeErrorAuthModal } from './replyForm'
import { niceNumber } from '../../helpers/niceNumber'

const RenderRating: FC<{ number: number }> = ({ number }) => {
	if (number > 0) {
		return <span className="color color--green">+{niceNumber(number)}</span>
	}

	if (number < 0) {
		return <span className="color color--red">-{niceNumber(Math.abs(number))}</span>
	}

	return <span className="color color--second">0</span>
}

const CommentCard: FC<{ comment: Comment; hideReply: boolean }> = ({
	comment,
	hideReply = false,
}) => {
	const { reply, changeReply } = useContext(ReplyContent)
	const [thumbsStat, setThumbsStat] = useState<ThumbsStat>(comment.thumbs_stat)

	const [activeReply, setActiveReply] = useState<boolean>(false)
	const [showChildren, setShowChildren] = useState<boolean>(true)
	const toggleChildrenShow = () => {
		setShowChildren(!showChildren)
	}

	const makeReport = () => {
		// window.makeReport({
		// 	key: comment.id,
		// 	type: 'Comment',
		// })
	}

	const toggleLike = async () => {
		try {
			await api(`/lk/user/rateUpComment/${comment.id}`, {
				method: `POST`,
				headers: {},
				body: JSON.stringify({}),
			})

			setThumbsStat({
				...thumbsStat,
				myVote: 'up',
				up: thumbsStat.myVote === 'up' ? thumbsStat.up : thumbsStat.up + 1,
				down: thumbsStat.myVote === 'down' ? thumbsStat.down - 1 : thumbsStat.down,
			})
		} catch (error) {
			makeErrorAuthModal({
				message: `Голосовать за комментарии и многое другое могут делать только зарегистрированные пользователи.`,
			})
			if (error.status === 403) {
				// makeSnack('error', `Для данного действия нужно войти`);
			}
		}
	}
	const toggleDislike = async () => {
		try {
			await api(`/lk/user/rateDownComment/${comment.id}`, {
				method: `POST`,
				headers: {},
				body: JSON.stringify({}),
			})
			setThumbsStat({
				...thumbsStat,
				myVote: 'down',
				up: thumbsStat.myVote === 'up' ? thumbsStat.up - 1 : thumbsStat.up,
				down: thumbsStat.myVote === 'down' ? thumbsStat.down : thumbsStat.down + 1,
			})
		} catch (error) {
			makeErrorAuthModal({
				message: `Голосовать за комментарии и многое другое могут делать только зарегистрированные пользователи.`,
			})
			if (error.status == 403) {
			}
		}
	}

	return (
		<div
			className={cn('comment', {
				'has-children': comment.children_comments.length > 0,
			})}
			id={`comment${comment.id}`}
		>
			<div className="comment-body">
				{window?.USER_ROLE === `admin` ? (
					<div className="admin-float-actions">
						<div className="content">
							<a
								href={`/admin/comments/view/${comment.id}`}
								target="_blank"
								className="item"
								rel="noreferrer"
							>
								Перейти
							</a>
						</div>
					</div>
				) : undefined}
				<div className="stage-1">
					<div className="left">
						{comment?.creator?.avatar_url === '' ? (
							<div
								className="avatar"
								style={{
									backgroundImage: `url(/img/owl.svg)`,
								}}
							/>
						) : (
							<div
								className="avatar"
								style={{
									backgroundImage: `url(${comment?.creator?.avatar_url})`,
								}}
							/>
						)}

						<div className="profile">
							<span className="name">{comment.creator.name}</span>
						</div>

						{!hideReply && !activeReply ? (
							<div
								className="btn btn--ghost btn--small btn--icon-left nmb"
								onClick={() => {
									changeReply(comment.id)
									setActiveReply(true)
								}}
							>
								<div className="icon icon--reply" />
								Ответить
							</div>
						) : undefined}
					</div>

					{/* <div className="date">{m(comment.created_at).format(`DD.MM.YYYY`)}</div> */}
				</div>

				<div className="stage-2">
					{!hideReply && !activeReply ? (
						<div
							className="btn btn--ghost btn--small btn--icon-left ndt"
							onClick={() => {
								changeReply(comment.id)
								setActiveReply(true)
							}}
						>
							<div className="icon icon--reply" />
							Ответить
						</div>
					) : undefined}
					<div className="content">{comment.content}</div>
				</div>
				<div className="stage-3">
					<div className="thumbs-wrap">
						<div
							className={cn(`like`, { active: thumbsStat.myVote === 'up' })}
							onClick={toggleLike}
						/>
						<RenderRating number={thumbsStat.up - thumbsStat.down} />
						<div
							className={cn(`dislike`, { active: thumbsStat.myVote === 'up' })}
							onClick={toggleDislike}
						/>
					</div>
				</div>

				{activeReply ? (
					<ReplyForm
						to={comment.id}
						onReply={() => {
							setActiveReply(false)
						}}
					/>
				) : undefined}
			</div>

			{comment.children_comments.length > 0 ? (
				<div
					className={cn(`children`, {
						active: showChildren,
					})}
				>
					{/* {showChildren ? ( */}
					{/*	<div onClick={toggleChildrenShow} className="toggle-children"> */}
					{/*		<div className="icon icon--arrow-up" /> */}
					{/*		Скрыть ответы */}
					{/*	</div> */}
					{/* ) : ( */}
					{/*	<div onClick={toggleChildrenShow} className="toggle-children"> */}
					{/*		<div className="icon icon--arrow-down" /> */}
					{/*		Показать ответы */}
					{/*	</div> */}
					{/* )} */}
					{showChildren &&
						comment.children_comments.map(children => (
							<CommentCard comment={children} hideReply={hideReply} key={children.id} />
						))}

					{/* {showChildren ? ( */}
					{/*	<div onClick={toggleChildrenShow} className="toggle-children"> */}
					{/*		<div className="icon icon--arrow-up" /> */}
					{/*		Скрыть ответы */}
					{/*	</div> */}
					{/* ) : undefined} */}
				</div>
			) : undefined}
		</div>
	)
}

export default CommentCard
