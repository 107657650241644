import React, { FC, useContext, useState } from 'react'
import { Formik } from 'formik'
import cn from 'classname'
import { FormInput } from '../../components/FormInput'
import { Comment, ReplyContent } from './index'
import smoothScrollTo from '../../common/smoothScrollTo'
import fetcher from '../../common/fetcher'

export function api<T>(url: string, options?: any): Promise<T> {
	// @ts-ignore
	let csrf = window.csrf || ''
	let new_options = {
		...options,
		credentials: 'include',
	}

	new_options.headers = {
		...new_options.headers,
		'X-CSRF-TOKEN': csrf,
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'X-Requested-With': 'XMLHttpRequest',
	}
	return new Promise<T>((resolve, reject) => {
		fetcher(url, new_options)
			.then(async response => {
				if (!response.ok) {
					let json = await response.json()
					if (json) {
						reject(json)
					}
					reject(response.statusText)
				}
				// @ts-ignore
				resolve(await response.json<T>())
			})
			.catch(async error => {
				reject(error)
			})
	})
}

export const makeErrorAuthModal = ({ message }: { message: string }): void => {
	// @ts-ignore
	window.makeModalAuth({
		message: `${message}`,
		// type: 'error',
		allowOverflow: false,
	})
}

const ReplyForm: FC<{
	to: null | number
	onReply?: () => void
}> = ({ to, onReply }) => {
	const { reply, replyUrl, updatePage } = useContext(ReplyContent)

	const [formMessage, setFormMessage] = useState<string | undefined>(undefined)

	const initialValues: { content: string } = { content: '' }

	if (formMessage) {
		return <p className="typography">{formMessage}</p>
	}

	// if (window?.USER_ROLE === `unauth`) {
	// 	return (
	// 		<p className="typography">
	// 			Только зарегистрированные пользователи могут оставлять комментарии.{' '}
	// 			<span className="link" onClick={() => openModal(`.modal-registration`)}>
	// 				Зарегистрируйтесь
	// 			</span>{' '}
	// 			или{' '}
	// 			<span className="link" onClick={() => openModal(`.modal-auth`)}>
	// 				войдите
	// 			</span>{' '}
	// 			в личный кабинет
	// 		</p>
	// 	)
	// }

	return (
		<div className="reply-comment-form main-reply-comment-form">
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, { setSubmitting, setErrors, setValues, resetForm }) => {
					let body: {
						content: string
						parent_id?: number
					} = {
						content: values.content,
						// search,
						// section
					}

					if (to !== null) {
						body.parent_id = to
					}

					try {
						let response = await api<{
							need_auth?: boolean
							formMessage?: string
							flash?: string
							comment_id?: string
						}>(`${replyUrl}`, {
							method: `POST`,
							headers: {},
							body: JSON.stringify(body),
						})

						if (response.need_auth) {
							window.openModal(`.modal-auth`)
							return false
						}

						resetForm()
						window.makeMessageModal({
							message: response.flash,
							icon: false,
						})

						if (typeof onReply === `function`) {
							onReply()
						}

						// if (response.formMessage) {
						// 	setFormMessage()
						// }

						updatePage()

						setTimeout(() => {
							smoothScrollTo({
								element: `#comment${response.comment_id}`,
							})
						}, 300)
					} catch (e) {
						if (e.errors) {
							setErrors(e.errors)
						} else {
							makeErrorAuthModal({
								message: `Оставлять комментарии и многое другое могут делать только зарегистрированные пользователи.`,
							})
						}
					}

					setSubmitting(false)
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<>
						{/* <div className="form-group"> */}
						<FormInput
							value={values.content}
							error={errors.content}
							onChange={handleChange('content')}
							type="textarea"
							// label="Текст комментария"
							placeholder="Текст комментария"
							required
						/>
						{/* </div> */}
						<div className="form-input">
							<button
								className={cn('btn btn--primary btn--block', {
									disabled: isSubmitting,
									loading: isSubmitting,
								})}
								onClick={handleSubmit}
							>
								Отправить
							</button>
						</div>
					</>
				)}
			</Formik>
		</div>
	)
}

export default ReplyForm
